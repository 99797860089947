import AllRoutes from "./router/index";
import Header from "./components/layers/Header";
import Footer from "./components/layers/Footer";
import MobileHeader from "./components/layers/MobileHeader";
import ScrollToTop from "./router/ScrollToTop";
import { useEffect, useState } from "react";
import axios from "axios";
import { tokenConfig } from './utility/auth'
import { BASE_DEV_URL, PLATFORM_URL } from "./utility/consts";
import CommingSoon from "./components/CommingSoon";

function App() {
  const [user, setUser] = useState();

  useEffect(() => {
    const iframe = document.getElementById('ifr');
    var win;
    try { win = iframe.contentWindow; } 
    catch(e) { win = iframe.contentWindow; }
    window.onmessage = function(e) {
      if (e.origin != PLATFORM_URL) return;
      if(!e.data) localStorage.removeItem("token");
      else localStorage.setItem("token", e.data)
      getUser();
    };
    win.postMessage(null, "*");
  }, [])

  const getUser = () => {
    axios
      .get(`${BASE_DEV_URL}/auth/user`, tokenConfig())
      .then((res) => {
        setUser(res.data.user)
      })
  }

  useEffect(() => {
    getUser();
  }, [])

  return (
    <>
      <Header user={ user } />
  
    <AllRoutes user={ user } />

      <MobileHeader/>
      <ScrollToTop >
      </ScrollToTop>
      <Footer/> 
    </>
  );
}

export default App;
