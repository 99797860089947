import { Routes, Route } from "react-router-dom";
import Homepage from "../pages/Homepage";
import Solution from "../pages/Solution";
import Pricing from "../pages/Price";
import Contact from "../pages/Contact";
import DemoRequest from "../pages/Demo";
import Terms from "../pages/Terms";
import Policy from "../pages/Policy";
import ZapierIntegration from "../pages/ZapierIntegration";
import CommingSoon from "../components/CommingSoon";

function AllRoutes({ user }) {
  return (
    <Routes>

      {/* <Route path="/" element={  <CommingSoon />} /> */}
      <Route path="*" element={  <Homepage />} />

      <Route path="/" element={<Homepage />} />
      {/* <Route path="/pricing" element={<Pricing user={ user } />} />
      <Route path="/solution" element={<Solution />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/demo" element={<DemoRequest />} />
      <Route path="/terms-of-service" element={<Terms />} />
      <Route path="/privacy-policy" element={<Policy />} />
      <Route path="/zapier-integration" element={<ZapierIntegration />} /> */}
    </Routes>
  );
}

export default AllRoutes;
