import React, { useEffect, useState } from "react";
import axios from "axios";
import { PLATFORM_URL } from "../utility/consts";

export default function Subscribe() {
  const [inputField, setInputField] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [formSuccess, setFormSuccess] = useState(false);
  const data = [
    "No need for developers",
    "Free to start",
    "Fully customizable",
  ];

  const handleChange = (e) => {
    setInputField(e.target.value);
  };

  const submitButton = (e) => {
    e.preventDefault();

    if (!inputField) {
      setMessage("Please correct your email address");
    } else {
      if (
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(inputField)
      ) {
        setMessage("");
        axios
          .post("https://beta.getformify.com/api/subscribe", {
            email: inputField,
          })
          .then(() => {
            setMessage("");
            setFormSuccess(true);
            setSuccess("Thank you for joining the waitlist. Look out for your email with a more detailed update about the beta for the new and enhanced Formify AI.");
            setInputField("");
          })
          .catch((err) => {
            setMessage("Please try again later!");
            console.log(err);
          });
      } else {
        setMessage("Please enter a valid email address");
      }
    }
  };

  useEffect(() => {
    console.log(formSuccess);
  }, [formSuccess]);
  return (
    <div className="form-container">
      {!formSuccess ? (
        <form className="subscribe-form">
          <div>
            <input
              placeholder="Type your email"
              type="text"
              onChange={handleChange}
              value={inputField}
              className="input-sub-placeholder"
            />
            <button onClick={submitButton}>
              {/* <span>Join</span> */}
              <img src="images/icons/next.svg" alt="next" />
            </button>
            {message ? <p className="error_message">{message}</p> : null}
            {success ? <p className="success_message">{success}</p> : null}
          </div>
        </form>
      ) : (
        <p className="success_message">{success}</p>
      )}
      <div className="tick-data">
        {data.map(function (d) {
          return (
            <div key={d}>
              <img src="images/icons/tick.svg" />
              <p>{d}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
