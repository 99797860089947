import React from "react";
import { NavLink } from "react-router-dom";
import Subscribe from "../Subscribe";
import { useLocation } from 'react-router-dom'
import logo from "../../images/formify_ai_logo.svg"


export default function Footer() {
  const location = useLocation();
  return (
    <footer>
      {location.pathname !== "/contact" && location.pathname !== "/demo" ?<div className="wrapper z-privilege">
        <div className="container">
          <div className="purple-container rounder-container">
            <div className="two-container">
              <div className="w-50">
                <h1>Collect leads, convert customers, grow your business.</h1>
              </div>
              <div className="w-50">
                <Subscribe />
              </div>
            </div>
          </div>
        </div>
      </div> : null
      }
      <div className="pink-background">
        <div className="wrapper">
          <div className="container social-wrapper">
          <img src={logo}  width={"300px"} height={"130px"}/>
            {/* <div className="social-media-container">
              <a href="https://www.facebook.com/GetFormify">
                <img src="images/icons/facebook.png" />
              </a>
              <a href="https://www.instagram.com/formify_/">
                <img src="images/icons/instagram.png" />
              </a>
              <a href="">
                <img src="images/icons/lnkedin.png" />
              </a>
              <a href="https://twitter.com/FormifyForm">
                <img src="images/icons/twitter.png" />
              </a>
            </div> */}
          </div>
          <div className="policy-data container">
            {/* <div>
              <NavLink to="/privacy-policy">Privacy Policy</NavLink>
              <NavLink to="/terms-of-service">Terms of Service</NavLink>
            </div> */}
            <p className="rights"> © {(new Date().getFullYear())} Formify. All rights reserved</p>
          </div>
          
        </div>
      </div>
    </footer>
  );
}
