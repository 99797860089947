import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../../images/formify_ai_logowhite.svg"

export default function MobileHeader() {
  const [show, setShow] = useState(false);
  const [navbar, setNavbar] = useState("");

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [show]);

  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 66) {
      setNavbar("white-color");
    } else {
      setNavbar("");
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  return (
    <>
      <div className={'mobile-header-wrapper ' + navbar}>
        <NavLink onClick={() => setShow(false)} to="/">              <img src={logo}  width={"120px"} height={"25px"}/>
</NavLink>
        {/* <span className="hamburger-menu" onClick={() => setShow(true)}></span> */}
      </div>
      {show && (
        <div className="mobile-header-layout">
          <div className="mobile-header">
            <div className="mobile-header-head">
              <NavLink to="/"><img src={logo}  width={"120px"} height={"25px"}/></NavLink>
              <span id="close-modal" onClick={() => setShow(false)}></span>
            </div>
            <div className="mobile-header-body">
              <ul>
                <ul>
                  <li>
                    <NavLink onClick={() => setShow(false)} to="/solution">Solution</NavLink>
                  </li>
                  <li>
                    <NavLink onClick={() => setShow(false)} to="/pricing">Pricing</NavLink>
                  </li>
                  <li>
                    <NavLink onClick={() => setShow(false)} to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </ul>
            </div>
            <div className="mobile-header-button">
              {/* <NavLink onClick={() => setShow(false)} className="orange-btn" to="/demo">
                Request a Demo
              </NavLink> */}
              <a className="purple-btn" href="beta.getformify.com/login">
                Login
              </a>
              <a className="white-button" href="beta.getformify.com/register">
                Sign Up
              </a>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
