import React from "react";
import AnimatedBanner from "../components/AnimatedBanner";
import Subscribe from "../components/Subscribe";
import { NavLink } from "react-router-dom";
import Lottie from "react-lottie-player";
import lottieJson from "../rotacioni.json";
import { Helmet } from "react-helmet";
import { PLATFORM_URL } from "../utility/consts";
export default function Homepage() {
  const banner_data = {
    desc: " So you can create a positively unfair advantage over all of your competitors starting right now 👇",
    image: "images/others/formbuilder-updatedimg.png",
  };

  const benefit_group = [
    {
      id: 1,
      class: "small-text",
      icon: "images/icons/img3.png",
      title: "Subscription Box Businesses",
      desc: "Build a visually stuning form that showcases the real benefits of your products.",
    },
    {
      id: 2,
      class: "small-text",
      icon: "images/icons/img2.png",
      title: "SaaS Company",
      desc: "Capture and securely store crucial information on leads on your go-to platforms.",
    },
    {
      id: 3,
      class: "bg-text",
      icon: "images/icons/img1.png",
      title: "Coaches, Mentors & Educators",
      desc: "Builds relationships with your prospects by leading them along an easy path to becoming customers.",
    },
  ];

  return (
    <>
      <Helmet>
        <title> Formify: Turn website traffic into recurring customers</title>
        <meta
          name="description"
          content="A drag-and-drop form builder designed for subscription businesses struggling to turn website traffic into loyal recurring customers. Sign-up for free today!"
        />
      </Helmet>
      <AnimatedBanner>
        <div className="banner-information">
          <h1>
            How to Make your Subscription Box Website Get You More Emails, Sales
            and Profits with One Simple Website Change That Takes Minutes to Set
            Up
          </h1>
          <p>{banner_data.desc}</p>
          <Subscribe />
        </div>
        <div className="bottom-image">
          <img src={banner_data.image} alt="image-data" />
        </div>
      </AnimatedBanner>
      <div className="wrapper sales-text">
        {/* <div className="center-data">
          <h1>More than just a checkout form</h1>
          
        </div> */}

        <p>
          Are you a subscription box founder struggling to make consistent
          sales?
        </p>
        <p>
          Are you tired of spending time, money and energy trying to increase
          traffic to your site but getting no sales in return?
        </p>
        <h4 className="orange-h4">Here's the problem:</h4>
        <p>
          Without a website optimised to convert, people won’t buy when they
          visit your site.
        </p>
        <p>
          Without a solid email marketing strategy, you won’t be able to remind
          anyone to come back and complete their order
        </p>
        <p>
          So, if neither of these are set up correctly, you can’t advertise
          without LOSING Money
        </p>
        <p>
          Luckily Formify AI solves both of these issues for you in a matter of
          minutes
        </p>
        <p>
          The solution to increasing conversions, and capturing{" "}
          <span className="purple-span">
            {" "}
            up to 1000% more emails than a popup
          </span>
        </p>
        <h4 className="purple-h4">…is a Signup Flow</h4>
        <p>
          And now, Formify AI allows you to build fully customisable signup
          flows for your subscription box business in 3 simple steps that take
          minutes…
        </p>
      </div>
      <div className="wrapper">
        <div className="container">
          <div className="two-container lightorange">
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/step1.png"
                alt="image-data"
              />
            </div>
            <div className="w-60">
              {/* <span className="section-icon"></span> */}
              <h2>STEP 1</h2>
              <h1>Design your signup flow</h1>
              <img
                className="border-image"
                src="images/others/border-bottom.png"
                alt="image-data"
              />
              <p>
                Take customers through a journey with your Formify form. Build
                trust,<br></br> provide additional context, and capture
                converting leads on the fly. <br></br> <br></br>
                Formify AI flows make growing your list easier then ever!
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
          </div>
          <div className="two-container lightyellow">
            <div className="w-60">
              {/* <span className="section-icon"></span> */}
              <h2>STEP 2</h2>
              <h1>
                Install it on your website <br></br>(works with
                <span class="underlined big">Any website</span> <br></br>{" "}
                builder)
              </h1>

              {/* <img
              className="border-image"
                src="images/others/border-bottom.png"
                alt="image-data"
              /> */}
              <p>
                Formify AI can easily be integrated to any website. A simple
                copy <br></br> paste will render the form on the desired page of
                your website!
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/step2.png"
                alt="image-data"
              />
            </div>
          </div>
          <div className="two-container lightpurple">
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/step3.svg"
                alt="image-data"
              />
            </div>
            <div className="w-60">
              {/* <span className="section-icon"></span> */}
              <h2>STEP 3</h2>
              <h1>
                {" "}
                <span class="underlined2 big">Integrate</span>it with your email{" "}
                <br></br>provider and your subscription <br></br> app
              </h1>

              <p>
                Based off what your customer’s answer, you can tailor the{" "}
                <br></br>
                products you offer to them. Different sizes or subscription{" "}
                <br></br>
                lengths, offer upsells and maximise the value generated for your{" "}
                <br></br>
                company.
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
          </div>
          <p>
            You’ve got the secret weapon that the big subscription boxes use to
            supercharge their subscription box growth <br></br>
            <br></br>
            (Except it didn’t cost you thousands of dollars to build it)
          </p>
        </div>
      </div>
      <div className="wrapper trusted-div">
        <h3>Trusted by</h3>
        <div className="trusted-by companies-trusted">
          <img
            className="container-image"
            src="images/others/busterlogo.png"
            alt="image-data"
          />
          <img
            className="container-image"
            src="images/others/horseboxlogo.png"
            alt="image-data"
          />
          <img
            className="container-image"
            src="images/others/fitreatlogo.png"
            alt="image-data"
          />
          <img
            className="container-image"
            src="images/others/dogmomlogo.png"
            alt="image-data"
          />
        </div>
      </div>
      <div className="wrapper">
        <div className="container">
          <div className="two-container lightformify">
            <div className="w-60">
              <h2>Turbocharge Your Email Collection</h2>
              <p>
                Over 250,000+ leads collected for BusterBox since we implemented
                our sign-up flow, our tool transforms your site into a powerful
                lead generation and sales machine!
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/turbocharge.png"
                alt="image-data"
              />
            </div>
          </div>
          <div className="two-container lightformify">
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/skyrock.png"
                alt="image-data"
              />
            </div>
            <div className="w-60">
              <h2>Skyrocket Your Sales</h2>
              <p>
                Boost your revenue dramatically, just as we did for BusterBox -
                generating an additional $3 million through an optimized sign-up
                flow! Experience the Formify AI effect on your bottom line!
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
          </div>
          <div className="two-container lightformify">
            <div className="w-60">
              <h2>Slash Your Customer Acquisition Cost (CAC)</h2>
              <p>
                As your lead collection multiplies with Formify AI, your
                opportunities to sell increase, effectively driving down your
                CAC. Invest less, gain more.
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/slash.png"
                alt="image-data"
              />
            </div>
          </div>
          <div className="two-container lightformify">
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/unlock.png"
                alt="image-data"
              />
            </div>
            <div className="w-60">
              <h2>Unlock Cross-Selling Opportunities</h2>
              <p>
                Use Formify AI to gather leads and explode your list, opening
                doors to additional revenue streams through the sale of other
                products. Amplify your earnings exponentially.
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
          </div>
          <div className="two-container lightformify">
            <div className="w-60">
              <h2>Improve Customer Retention</h2>
              <p>
                Formify AI helps collect vital customer preferences, empowering
                you to serve your subscribers better and significantly reduce
                churn. Better service, better retention.
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/costumer.png"
                alt="image-data"
              />
            </div>
          </div>
          <div className="two-container lightformify">
            <div className="w-40">
              <img
                className="container-image"
                src="images/others/elevate.png"
                alt="image-data"
              />
            </div>
            <div className="w-60">
              <span className="new-in-formify">NEW IN FORMIFY</span>
              <h2>Elevate Your Subscription Box Marketing with AI</h2>
              <p>
                With CopyGuru AI, take your marketing strategies to new heights.
                Craft compelling sales copy using our proven direct response
                framework, making it easier than ever to achieve outstanding
                results and propel your subscription box business forward.
              </p>
              <a className="orange-btn" href={`${PLATFORM_URL}/register`}>
                Get started
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper trusted-div">
        <h3>Results</h3>
        <div className="trusted-by start-aligned">
          <img
            className="container-image"
            src="images/others/result1.png"
            alt="image-data"
          />
          <img
            className="container-image"
            src="images/others/result2.png"
            alt="image-data"
          />
          <img
            className="container-image"
            src="images/others/result3.png"
            alt="image-data"
          />
        </div>
      </div>
      <div className="wrapper">
        <div className="container launch-offer">
          <h3>EXCLUSIVE LAUNCH OFFER</h3>

          <div>
            <p>
              When you join Formify AI’s premium plan before March 31st 2024.
              You will also unlock access to:
            </p>
            <h5>Free weekly group coaching for 3 months</h5>
            <p>
              Join Gary Redmond (and maybe Liam Brennan & Paul Carrick) on a
              weekly 60-minute coaching session all summer long where he will
              help you to smash your subscriber goals in your subscription box
              business. We’ll cover conversion rate optimisation, email
              marketing, Facebook advertising, product sourcing, negotiation and
              much much more.
            </p>
            <h5>Free signup Flow Training</h5>
            <p>
              What questions to ask? What styling is best? Hidden signup flow
              features? How to make the most money?
            </p>
            <h5>Free Email Marketing Training by Gary Redmond</h5>
            <h5>Conversion-optimised Subscription Box Website Templat</h5>
            <h5>Free Offer Creation Training by Liam Brennan</h5>
            <p>
              Swipe our proven high-converting website template. Pre-built in
              Canva for you to simply drop in your images and sales copy and
              start converting like crazy…
            </p>
            <h5>High-Converting Email Templates</h5>
            <p>
              Swipe the exact email frameworks that have signed up tens of
              thousands of subscription customers and generated over $3 Million
              in recurring sales using nothing but free emails.
            </p>
            <h5>7 Figure Mastermind</h5>
            <p>
              You will be added to a mastermind group with hundreds of other
              committed founders, ranging from 5 to 7 figures in sales and
              beyond. We will help you to get the best results not only from
              Formify AI but take your Subscription Box Business to the next
              level together.
            </p>
            <p>
              The investment for all of these game-changing tools, coaching and
              growth hacks is just $97 p/m. Cancel anytime
            </p>
            <p className="red-information">Launch Offer Ends March 31st 2024</p>
          </div>
        </div>
      </div>
      {/* <div className="wrapper">
        <div className="container">
          <div className="three-data">
            <div className="one-info">
              <span className="custom-fields span-icon"></span>
              <h1>Custom Fields</h1>
              <p>
                Pass these fields to your email or CRM tool for hyper
                personalised re-marketing
              </p>
            </div>
            <div className="one-info">
              <span className="customizable-design span-icon"></span>
              <h1>Customizable Design</h1>
              <p>
                Create a visually stunning checkout forms that fit the design of
                your online store.
              </p>
            </div>
            <div className="one-info">
              <span className="deep-data-integration span-icon"></span>
              <h1>Deep-Data Integrations</h1>
              <p>
                Upsell customers onto subscriptions, upgrades and add-ons to
                easily increase average revenue per sale
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="purple-container">
        <div className="wrapper">
          <div className="container">
            <div className="two-container">
              <div className="w-50">
                <h1>Connect Formify with your store in seconds</h1>
                <p>
                  With the help of our growing list of integrations, turning
                  leads into customers will be a breeze.
                </p>
                <NavLink to="/demo">Register</NavLink>
              </div>
              <div className="w-50">
                <Lottie loop animationData={lottieJson} play />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <div className="container p-50">
          <h1 className="title">Who can benefit</h1>
          <div className="three-data">
            {benefit_group.map((data) => (
              <div className="one-info gray-bck" key={data.id}>
                <img src={data.icon} />
                <div>
                  <h1 className={data.class}>{data.title}</h1>
                  <p>{data.desc}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div> */}
    </>
  );
}
