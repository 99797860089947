import React from "react";
import animatedbanner from "../images/animatedbanner.png";
export default function AnimatedBanner(props) {
  return (
    <div className="banner-container">
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        preserveAspectRatio="xMidYMid"
        viewBox="0 0 1920 937"
        className="animation-svg2"
      >
        <g
          transform="translate(960,468.5) scale(1,1) translate(-960,-468.5)"
          className="svg-2transform"
        >
          <linearGradient
            className="svg-2transform"
            id="lg-0.21562751880819286"
            x1="0"
            x2="1"
            y1="0"
            y2="0"
          >
            <stop
              stopColor="#6563FF"
              offset="0"
              className="svg-2transform"
            ></stop>
            <stop stopColor="#163ff3" offset="1"></stop>
          </linearGradient>
          <path
            className="wave-animations"
            d="M 0 0 L 0 785.423 Q 192 794.462 384 775.653 T 768 651.582 T 1152 559.153 T 1536 559.38 T 1920 501.472 L 1920 0 Z"
            fill="url(#lg-0.21562751880819286)"
            opacity="0.4"
          ></path>
          <path
            className="wave-animations"
            d="M 0 0 L 0 816.587 Q 192 749.288 384 722.552 T 768 628.309 T 1152 548.873 T 1536 492.077 T 1920 404.352 L 1920 0 Z"
            fill="url(#lg-0.21562751880819286)"
            opacity="0.4"
          ></path>
          <path
            className="wave-animations"
            d="M 0 0 L 0 887.908 Q 192 794.021 384 772.616 T 768 705.457 T 1152 535.69 T 1536 406.277 T 1920 411.655 L 1920 0 Z"
            fill="url(#lg-0.21562751880819286)"
            opacity="0.4"
          ></path>
          <path
            className="wave-animations"
            d="M 0 0 L 0 844.038 Q 192 839.912 384 803.758 T 768 597.192 T 1152 552.78 T 1536 426.397 T 1920 466.585 L 1920 0 Z"
            fill="url(#lg-0.21562751880819286)"
            opacity="0.4"
          ></path>
          <path
            className="wave-animations"
            d="M 0 0 L 0 866.302 Q 192 854.195 384 825.588 T 768 664.038 T 1152 612.087 T 1536 548.914 T 1920 389.759 L 1920 0 Z"
            fill="url(#lg-0.21562751880819286)"
            opacity="0.4"
          ></path>
        </g>
      </svg> */}
      <img className="animation-svg2" src={animatedbanner}></img>
      <div className="banner-data">
          {props.children}
      </div>
    </div>
  );
}
