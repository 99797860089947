export const PLATFORM_URL = 
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://beta.getformify.com";

export const WEBISTE_URL = 
  process.env.NODE_ENV === "development"
    ? "http://localhost:3001"
    : "https://getformify.com";

export const BASE_DEV_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000/api"
    : "https://beta.getformify.com/api";