import React,{useState, useEffect} from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom'
import { PLATFORM_URL } from "../../utility/consts";
import logo from "../../images/formify_ai_logowhite.svg"

export default function Header({ user }) {
  const [navbar, setNavbar] = useState("");
  const location = useLocation();
  const active_location = location.pathname;

  const changeBackground = () => {
    // console.log(window.scrollY);
    if (window.scrollY >= 66) {
      setNavbar("white-color");
    } else {
      setNavbar("");
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });
  
  return (
    <header className={navbar}>
      <div className="container">
        <div className="header-wrapper">
          <NavLink className="logo-container" to="/">{navbar.length > 0 ? (
              <img src={logo}  width={"143px"} height={"30px"}/>
            ) : (
              active_location !=='/contact' && active_location !== '/demo' ? <img src={logo}  width={"143px"} height={"30px"}/> : <img src={logo}  width={"143px"} height={"30px"}/>
            )}
            </NavLink>
          <div className="menu-container">
            <ul>
              {/* <li>
                <NavLink to="/solution">Solution</NavLink>
              </li>
              <li>
                <NavLink to="/pricing">Pricing</NavLink>
              </li>
              <li>
                <NavLink to="/contact">Contact</NavLink>
              </li> */}
              { !user && (
                <>
                  <li>
                    <a className="orange-btn" href={`${PLATFORM_URL}/register`}>Get started 
                <img style={{marginLeft:"10px"}} src="images/icons/next.svg" alt="next" />
                    
                    </a>
                  </li>
                  {/* <li>
                    <a className="purple-btn" href={`${PLATFORM_URL}`}>Login</a>
                  </li> */}
                </>
              )}
              { user && (
                <a href={`${PLATFORM_URL}`}>
                  <img width={44} height={44} style={{ borderRadius: '50%' }} src={ user.profile_picture } alt="Profile pic" />
                </a>
              )}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}
