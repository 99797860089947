const config = () => {
  return {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const tokenConfig = () => {
  const conf = config();
  const token = localStorage.getItem("token");
  if (token) conf.headers["Authorization"] = `Bearer ${token}`;
  return conf;
};